var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-charts", {
    ref: "line",
    attrs: {
      autoresize: "",
      options: _vm.option,
      theme: "theme-color",
      "auto-resize": "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }