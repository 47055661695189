var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { title: "Horizontal Bar Chart" } },
    [_c("app-echart-bar", { attrs: { "option-data": _vm.option } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }