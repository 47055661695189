var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { cols: "12" } }, [_c("echart-line")], 1),
      _c("b-col", { attrs: { cols: "12" } }, [_c("echart-stacked-area")], 1),
      _c("b-col", { attrs: { cols: "12" } }, [_c("echart-area")], 1),
      _c("b-col", { attrs: { cols: "12" } }, [_c("echart-bar")], 1),
      _c("b-col", { attrs: { cols: "12" } }, [_c("echart-bar-Horizontal")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("echart-doughnut")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("echart-radar")], 1),
      _c("b-col", { attrs: { cols: "12" } }, [_c("echart-scatter")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }